import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49f95de8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chartCard" }
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = { class: "metaWrap" }
const _hoisted_4 = { class: "meta" }
const _hoisted_5 = {
  key: 0,
  class: "title"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "action" }
const _hoisted_8 = {
  key: 0,
  class: "total"
}
const _hoisted_9 = {
  key: 1,
  class: "total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    loading: _ctx.loading,
    bordered: _ctx.bordered,
    "body-style": { padding: '20px 24px 8px 24px' }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['chartTop', { chartTopMargin: !_ctx.$slots.default && !_ctx.$slots.footer }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "avatar", {}, undefined, true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.title), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                  ])),
              _createElementVNode("span", _hoisted_7, [
                _renderSlot(_ctx.$slots, "action", {}, undefined, true)
              ])
            ]),
            (_ctx.total)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.total), 1))
              : (_ctx.$slots.total)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _renderSlot(_ctx.$slots, "total", {}, undefined, true)
                  ]))
                : _createCommentVNode("", true)
          ])
        ], 2),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "content",
              style: _normalizeStyle({ height: _ctx.contentHeight ? `${_ctx.contentHeight}px` : 'auto' })
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.contentHeight ? 'contentFixed' : '')
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2)
            ], 4))
          : _createCommentVNode("", true),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(['footer', { footerMargin: !!_ctx.$slots.default }])
            }, [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["loading", "bordered"]))
}