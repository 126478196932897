
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    total: {
      type: [Number, String],
      default: () => undefined,
    },
    contentHeight: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => undefined,
    },
    bordered: {
      type: Boolean,
      default: () => false,
    },
  },
});
